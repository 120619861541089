import React, { useEffect, useState } from "react";
import { createFileRoute, Link, redirect } from "@tanstack/react-router";
import { useGetMarkingSessionSummary } from "@/lib/apis/v2/queries/getMarkingSessionSummary";
import { useUpsertMarkingSession } from "@/lib/apis/v2/mutations/upsertMarkingSession";
import { Maybe, VersionWorksheetDetail } from "@/graphql/generated/graphql"; // Import generated types
import { useGetWorksheetSetVersionDetail } from "@/lib/apis/v2/queries/getWorksheetSetVersionDetail";
import { useGetAssignmentDetail } from "../../../../../../../../../../lib/apis/v2/queries/getAssignmentDetail";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useUpdateWorksheetSetAssigmentAsComplete } from "@/lib/apis/v2/mutations/updateWorksheetSetAssigmentAsComplete";
import { useQueryClient } from "@tanstack/react-query";
import { Skeleton } from "@/components/ui/skeleton";
import StudentList from "@/components/student-list";
import Breadcrumbs from "@/components/breadcrumbs";
import MarkingSessionOverrideModal from "@/features/marking-session-override/components/modal/override-modal";
import { useGetMarkingSessionOverride } from "@/lib/apis/v2/queries/getMarkingSessionOverride";
import { cn } from "@/lib/utils";

export const Route = createFileRoute(
  "/_auth/teacher/session/$sessionId/student/$userId/set/$setVersionId/scores/"
)({
  component: () => <ScoreCard />,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
    if (context.auth.user?.role === "student") {
      throw redirect({ to: "/student" });
    }
  },
});

function ScoreCard() {
  const { userId, setVersionId, sessionId } = Route.useParams();
  const queryClient = useQueryClient();
  const { data: dataSummary, refetch: refetchSummary } =
    useGetMarkingSessionSummary({
      garageUserId: Number(userId),
      garageClassroomSessionId: Number(sessionId),
      worksheetSetVersionId: Number(setVersionId),
    });

  const { data: dataAssigment, refetch: refetchAssigmentDetail } =
    useGetAssignmentDetail({
      garageClassroomSessionId: Number(sessionId),
      garageUserId: Number(userId),
      worksheetSetVersionId: Number(setVersionId),
      enabled: !!sessionId && !!userId && !!setVersionId,
    });

  const {
    data: dataWorksheetSetVersion,
    isPending: isPendingWorksheetSetVersion,
    refetch: refetchWorksheetSetVersion,
  } = useGetWorksheetSetVersionDetail({
    worksheetSetVersionId: Number(setVersionId),
    worksheetSetId: Number(
      dataAssigment?.getAssignmentDetail?.worksheetSetVersion?.worksheetSet?.id
    ),
    enabled: !!dataAssigment?.getAssignmentDetail?.worksheetSetVersion?.id,
  });

  const [pointsState, setPointsState] = useState<Record<string, number>>({});
  const [showCompleteConfirmation, setShowCompleteConfirmation] =
    useState(false);
  const [showUnchangeableAlert, setShowUnchangeableAlert] = useState(false);

  const upsertMarkingSessionMutation = useUpsertMarkingSession();

  const updateWoksheetSetAsComplete = useUpdateWorksheetSetAssigmentAsComplete({
    worksheetSetAssignmentId: Number(dataAssigment?.getAssignmentDetail?.id),
  });

  const handlePointsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    questionId: string,
    totalPoints: number
  ) => {
    const newPoints = parseInt(e.target.value, 10) || 0;
    if (newPoints < 0 || newPoints > totalPoints) {
      setPointsState((prev) => ({
        ...prev,
        [questionId]: totalPoints,
      }));
      return;
    }
    setPointsState((prev) => ({
      ...prev,
      [questionId]: newPoints,
    }));
  };

  const handleSubmit = () => {
    upsertMarkingSessionMutation.mutate({
      worksheetSetAssignmentId: Number(dataAssigment?.getAssignmentDetail?.id),
      markedQuestions: Object.entries(pointsState).map(
        ([worksheetPageQuestionId, points]) => ({
          worksheetPageQuestionId: parseInt(worksheetPageQuestionId),
          points,
        })
      ),
    });
    if (!dataAssigment?.getAssignmentDetail?.isMarkingComplete) {
      setShowCompleteConfirmation(true);
    }
  };

  const handleMarkAsComplete = () => {
    setShowCompleteConfirmation(false);
    updateWoksheetSetAsComplete.mutate();
    refetchAssigmentDetail();
    refetchSummary();
    refetchWorksheetSetVersion();
    queryClient.invalidateQueries();
  };

  // const handleAlertUncahngeable = () => {
  //   setShowUnchangeableAlert(true);
  // };

  // Using types from the generated schema and handling nullable fields
  const calculateTableSummary = (
    worksheet: Maybe<VersionWorksheetDetail> | undefined | null
  ): {
    worksheetTotalPoints: number;
    worksheetMaxPoints: number;
    percentage: string;
    isPassing: boolean;
  } => {
    let worksheetTotalPoints = 0;
    let worksheetMaxPoints = 0;

    worksheet?.pages?.forEach((page) => {
      page?.questions?.forEach((question) => {
        const questionId = question?.id ?? "";
        const points =
          pointsState[questionId] ??
          dataSummary?.getMarkingSessionSummary?.worksheets
            ?.find((item) => item?.id === worksheet.id)
            ?.pages?.find((item) => item?.id === page?.id)
            ?.questions?.find((item) => item?.id === questionId)
            ?.markedQuestions?.[0]?.points ??
          0;
        worksheetTotalPoints += points;
        worksheetMaxPoints += question?.totalPoints ?? 0;
      });
    });

    const percentage = (
      (worksheetTotalPoints / worksheetMaxPoints) *
      100
    ).toFixed(1);
    const isPassing = parseFloat(percentage) >= 75;
    return { worksheetTotalPoints, worksheetMaxPoints, percentage, isPassing };
  };

  useEffect(() => {
    // Create a new marking session if it doesn't exist
    // Required for Overide Modal
    if (
      !dataSummary?.getMarkingSessionSummary?.id &&
      dataAssigment?.getAssignmentDetail?.id
    ) {
      upsertMarkingSessionMutation.mutate({
        worksheetSetAssignmentId: Number(
          dataAssigment?.getAssignmentDetail?.id
        ),
        markedQuestions: [],
      });
    }
  }, [dataSummary, dataAssigment]);

  const { data: dataSessionOverride } = useGetMarkingSessionOverride({
    where: {
      markingSessionId: {
        eq: dataSummary?.getMarkingSessionSummary?.id,
      },
      worksheetSetVersionId: {
        eq: Number(setVersionId),
      },
    },
    enabled: !!dataSummary?.getMarkingSessionSummary?.id,
  });

  // Initialize pointsState with existing values from dataSummary
  useEffect(() => {
    if (dataSummary?.getMarkingSessionSummary?.worksheets) {
      const initialPoints: Record<string, number> = {};
      
      dataSummary.getMarkingSessionSummary.worksheets.forEach((worksheet) => {
        worksheet?.pages?.forEach((page) => {
          page?.questions?.forEach((question) => {
            const questionId = question?.id;
            const points = question?.markedQuestions?.[0]?.points;
            
            if (questionId && (points !== undefined && points !== null)) {
              initialPoints[questionId] = points;
            }
          });
        });
      });

      setPointsState(initialPoints);
    }
  }, [dataSummary]);

  return (
    <div className="flex min-h-screen w-full flex-col">
      <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
        <div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
          <div>
            <StudentList
              sessionId={Number(sessionId)}
              activeStudentId={Number(userId)}
            />
          </div>
          <div className="container mx-auto p-4">
            <AlertDialog open={showUnchangeableAlert}>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    Marking Session is Complete
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    This Worksheet set has been marked as complete. You will not
                    be able to make any changes.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogAction
                    onClick={() => setShowUnchangeableAlert(false)}
                  >
                    Understood
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            <AlertDialog open={showCompleteConfirmation}>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    Do you want mark this session as complete?
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    Once completed you will not be able to make any changes.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel
                    onClick={() => setShowCompleteConfirmation(false)}
                  >
                    Cancel
                  </AlertDialogCancel>
                  <AlertDialogAction onClick={handleMarkAsComplete}>
                    Mark as Complete
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            <div className="grid gap-4">
              <Breadcrumbs />
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold">Score Card</h1>
                <div className="">
                  Marking Status:{" "}
                  {dataAssigment?.getAssignmentDetail?.isMarkingComplete ? (
                    <span className="text-green-500">Completed</span>
                  ) : (
                    <span className="text-red-500">Incomplete</span>
                  )}
                </div>
              </div>
            </div>
            {dataWorksheetSetVersion?.getWorksheetSetVersionDetail?.worksheets?.map(
              (worksheet) => {
                return (
                  <div key={worksheet?.versionId} className="my-4">
                    <div className="text-xl font-semibold p-4 border border-blue-400">
                      {isPendingWorksheetSetVersion ? (
                        <Skeleton className="h-[125px] w-full rounded-xl" />
                      ) : (
                        <>
                          <div>
                            {`Worksheet Set ${dataWorksheetSetVersion?.getWorksheetSetVersionDetail?.id} - Version ${dataWorksheetSetVersion?.getWorksheetSetVersionDetail?.version}`}
                          </div>
                          <div className="grid gap-1">
                            {/* <div>{`Worksheet ID: ${worksheet?.id}`}</div> */}
                            {/* <div>{`Worksheet Version ID: ${worksheet?.versionId}`}</div> */}
                            <div>{`Book: ${worksheet?.book?.name}`}</div>
                            <div>{`Sub Unit: ${worksheet?.subunit?.name}`}</div>
                          </div>
                        </>
                      )}
                    </div>
                    <table className="w-full table-auto border-collapse">
                      <thead>
                        <tr>
                          <th className="border p-2" rowSpan={2}>
                            Worksheet Name
                          </th>
                          <th className="border p-2" rowSpan={2}>
                            Page No.
                          </th>
                          <th className="border p-2" rowSpan={2}>
                            Question
                          </th>
                          {/* Group "Markings" */}
                          <th className="border p-2" colSpan={2}>
                            Markings
                          </th>
                          <th className="border p-2" rowSpan={2}>
                            Status
                          </th>
                        </tr>
                        <tr>
                          {/* Sub-columns under "Markings" */}
                          <th className="border p-2">Points</th>
                          <th className="border p-2">Total Points</th>
                        </tr>
                      </thead>
                      <tbody>
                        {worksheet?.pages?.map((page) =>
                          page?.questions?.map((question, questionIndex) => {
                            const questionId = question?.id ?? "";
                            const totalPoints = question?.totalPoints ?? 0;
                            const points = pointsState[questionId] ?? null ??'';
                            const isPassing = points / totalPoints >= 0.75;

                            return (
                              <tr key={questionId}>
                                <td className="border p-2">
                                  <Link
                                    className="hover:text-blue-500 cursor-pointer"
                                    to="/teacher/session/$sessionId/student/$userId/set/$setVersionId/worksheet/$worksheetVersionId"
                                    params={{
                                      sessionId: sessionId,
                                      userId: userId,
                                      setVersionId: setVersionId,
                                      worksheetVersionId: String(
                                        worksheet?.versionId
                                      ),
                                    }}
                                  >
                                    {worksheet?.name} - Version{" "}
                                    {worksheet.version}
                                  </Link>
                                </td>
                                <td className="border p-2">
                                  {page.pageNumber}
                                </td>
                                <td className="border p-2">
                                  {
                                    worksheet.pages
                                      ?.find((item) => item?.id === page.id)
                                      ?.questions?.find(
                                        (item) => item?.id === question?.id
                                      )?.content
                                  }
                                </td>
                                {/* User Points input */}
                                <td className="border p-2 text-center">
                                  <input
                                    type="number"
                                    max={totalPoints}
                                    value={points}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const initialPoints = dataSummary?.getMarkingSessionSummary?.worksheets
                                        ?.find((item) => item?.id === worksheet.id)
                                        ?.pages?.find((item) => item?.id === page?.id)
                                        ?.questions?.find((item) => item?.id === questionId)
                                        ?.markedQuestions?.[0]?.points;
                                      
                                      // Allow empty value only if there was no initial points
                                      if (value === '') {
                                        if (initialPoints === null || initialPoints === undefined) {
                                          setPointsState((prev) => {
                                            const newState = { ...prev };
                                            delete newState[questionId];
                                            return newState;
                                          });
                                          return;
                                        } else {
                                          // If there were initial points, set to 0 instead of empty
                                          setPointsState(prev => ({
                                            ...prev,
                                            [questionId]: 0
                                          }));
                                          return;
                                        }
                                      }
                                      handlePointsChange(
                                        e,
                                        String(questionId),
                                        totalPoints
                                      );
                                    }}
                                    onFocus={() => {
                                      if (!points && points !== 0) {
                                        // Only set to 0 if there's no existing value
                                        setPointsState((prev) => ({
                                          ...prev,
                                          [questionId]: 0,
                                        }));
                                      }
                                    }}
                                    className="w-16 border p-1"
                                    placeholder="Points"
                                    aria-label={`Input points for Question ${questionIndex + 1}`}
                                  />
                                </td>
                                {/* Total Points */}
                                <td className="border p-2 text-center">
                                  {totalPoints}
                                </td>
                                {/* Status */}
                                <td className="border p-2">
                                  {isPassing ? (
                                    <span className="text-green-500">PASS</span>
                                  ) : (
                                    <span className="text-red-500">FAIL</span>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        )}
                        {/* Summary Row for the entire table (multiple worksheets) */}
                        <tr>
                          <td className="border p-2 font-bold" colSpan={3}>
                            <div className="flex gap-1 justify-between">
                              <div>Summary</div>
                              {dataSummary?.getMarkingSessionSummary?.id && (
                                <MarkingSessionOverrideModal
                                  markingSessionId={Number(
                                    dataSummary?.getMarkingSessionSummary?.id
                                  )}
                                  worksheetSetVersionId={Number(setVersionId)}
                                  worksheetVersionId={Number(
                                    worksheet?.versionId
                                  )}
                                  worksheetName={`${worksheet?.name} - Version ${worksheet?.version}`}
                                />
                              )}
                            </div>
                          </td>
                          <td className="border p-2 text-center font-bold">
                            {
                              calculateTableSummary(worksheet)
                                ?.worksheetTotalPoints
                            }
                          </td>
                          <td className="border p-2 text-center font-bold">
                            {
                              calculateTableSummary(worksheet)
                                ?.worksheetMaxPoints
                            }
                          </td>
                          <td className="border p-2 font-bold">
                            <div className="flex items-center">
                              <div className="grid gap-1">
                                {/*  Initial Status */}
                                <div className="flex gap-1">
                                  <div>
                                    {
                                      calculateTableSummary(worksheet)
                                        ?.percentage
                                    }
                                    %
                                  </div>
                                  <div>-</div>
                                  <div>
                                    {calculateTableSummary(worksheet)
                                      ?.isPassing ? (
                                      <span className="text-green-500">
                                        PASS
                                      </span>
                                    ) : (
                                      <span className="text-red-500">FAIL</span>
                                    )}
                                  </div>
                                </div>
                                {/* Overrided Status */}
                                {dataSessionOverride?.markingSessionOverride?.find(
                                  (item) =>
                                    item.worksheetVersionId ===
                                    worksheet?.versionId
                                )?.overrideStatus && (
                                  <div className="flex text-xs gap-1">
                                    Overrided to
                                    <div
                                      className={cn("", {
                                        "text-green-500":
                                          dataSessionOverride?.markingSessionOverride?.find(
                                            (item) =>
                                              item.worksheetVersionId ===
                                              worksheet?.versionId
                                          )?.overrideStatus === "pass",
                                        "text-red-500":
                                          dataSessionOverride?.markingSessionOverride?.find(
                                            (item) =>
                                              item.worksheetVersionId ===
                                              worksheet?.versionId
                                          )?.overrideStatus === "fail",
                                      })}
                                    >
                                      {dataSessionOverride?.markingSessionOverride
                                        ?.find(
                                          (item) =>
                                            item.worksheetVersionId ===
                                            worksheet?.versionId
                                        )
                                        ?.overrideStatus.toUpperCase()}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              }
            )}

            <button
              className="bg-blue-500 text-white p-2 mt-4"
              onClick={handleSubmit}
              disabled={upsertMarkingSessionMutation.isPending}
            >
              {upsertMarkingSessionMutation.isPending
                ? "Submitting..."
                : "Submit"}
            </button>

            {upsertMarkingSessionMutation.isError && (
              <p className="text-red-500 mt-2">Error submitting the data</p>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default ScoreCard;
